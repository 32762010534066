import * as React from 'react';
import { connect } from 'react-redux';

import { AuthTimer } from './AuthTimer';

import { CookieSessionData } from '../Models/CookieSessionData';
import { LogException } from '../Utils/Logging';

import { ApplicationState } from '../Store';
import { GetAulsCookiesWhenTickerChanged } from '../Store/UIState';

type InjectedReduxState = {
    cookieSessionData?: CookieSessionData;
};

type InjectedActionCreators = {}

type AuthTimerControlProps = {
    mainDivKey: string;
    auth0Token: string | undefined;
    onTimeout: () => void;
    onSlidingTimeout: () => void;
}

type Props = AuthTimerControlProps & InjectedActionCreators & InjectedReduxState;

export const AuthTimerControllerComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        auth0Token,
        mainDivKey,
        onSlidingTimeout,
        onTimeout,
        cookieSessionData,
    } = props;

    const [sessionExpirationTimes, setSessionExpirationTimes] = React.useState<{
        absolute: Date,
        absoluteRaw: string,
        sliding: Date,
        slidingRaw: string,
    } | undefined>(undefined);

    React.useEffect(() => {
        try {
            if (!!cookieSessionData) {
                if (!sessionExpirationTimes ||
                    (sessionExpirationTimes.slidingRaw !== cookieSessionData.slidingExpirationTime) ||
                    (sessionExpirationTimes.absoluteRaw !== cookieSessionData.absoluteExpirationTime)
                ) {
                    if (!(
                        cookieSessionData.absoluteExpirationTime && cookieSessionData.slidingExpirationTime
                    )) {
                        setSessionExpirationTimes(undefined);
                    } else {
                        setSessionExpirationTimes({
                            absolute: new Date(cookieSessionData.absoluteExpirationTime),
                            absoluteRaw: cookieSessionData.absoluteExpirationTime,
                            sliding: new Date(cookieSessionData.slidingExpirationTime),
                            slidingRaw: cookieSessionData.slidingExpirationTime,
                        });
                    }
                }
            } else {
                setSessionExpirationTimes(undefined);
            }
        } catch (exception) {
            LogException(
                'Failed retrieving session data from cookie',
                exception
            );
        }
        // cookieSessionData is the only dependency I actually want.
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [cookieSessionData]);

    return (
        <div key={mainDivKey}>
            <AuthTimer
                absoluteExpiration={sessionExpirationTimes && sessionExpirationTimes.absolute}
                slidingExpiration={sessionExpirationTimes && sessionExpirationTimes.sliding}
                token={auth0Token}
                onTimeout={onTimeout}
                onSlidingTimeout={onSlidingTimeout}
            />
        </div>
    );
}

export const AuthTimerController = connect<InjectedReduxState, InjectedActionCreators, AuthTimerControlProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
            cookieSessionData: GetAulsCookiesWhenTickerChanged(appState),
        };

        return result;
    }
)(AuthTimerControllerComponent);
