import { ApplicationState } from '../index';
import { ModifyWorkspaceUsersState, UIRedirectInfo } from './Reducer';
import { CookieSessionData } from '../../Models/CookieSessionData';

export function GetAddUsers(state: ApplicationState): boolean {
    return !!(state && state.uiState && state.uiState.showingAddUsers);
}

export function UserCanNavigateHome(state: ApplicationState): boolean {
    return (!!state && !!state.uiState && state.uiState.allowHomeNavigate);
}

export function GetPortalBusyState(state: ApplicationState): boolean {
    return (!!state && !!state.uiState && (state.uiState.working !== 0))
}

export function GetRedirectAfterLogin(state: ApplicationState): UIRedirectInfo | undefined {
    return (state && state.uiState && state.uiState.redirectAfterLogin);
}

export function GetModifyWorkspaceUsersState(state: ApplicationState): ModifyWorkspaceUsersState | undefined {
    return (state && state.uiState && state.uiState.modifyWorkspaceUserState);
}

export function MillisecondCountLastUserInteraction(state: ApplicationState): number {
    if (!(state && state.uiState)) {
        return 0;
    }
    return state.uiState.lastUserInteractionUpdateTime;
}

export function GetRefreshStateTicker(state: ApplicationState): number {
    if (!(state && state.uiState)) {
        return 0;
    }
    return state.uiState.uiStateUpdateTicker;
}

export function GetAulsCookiesWhenTickerChanged(state: ApplicationState): CookieSessionData | undefined {
    if (!(state && state.uiState)) {
        return undefined;
    }
    return state.uiState.cookieSessionData;
}
