import { Reducer } from 'redux';

import { LogException } from '../../Utils/Logging';

import { randomBytes } from 'crypto';
import { EntityDetailResponse, UserResponse } from '../../Models/Api/strongbox.financialportal';
import { CookieSessionData } from '../../Models/CookieSessionData';

import {
    UIStateActions,
    KnownAction,
} from './Actions';

// import cryptoRandomString from 'crypto-random-string';

export type UIRedirectSearchParams = {
    searchParams: { key: string; value: string }[];
}

export type UIRedirectInfo = {
    path: string;
    nonce: string;
    searchParams?: UIRedirectSearchParams;
}

export type ModifyWorkspaceUsersState = {
    workspace: EntityDetailResponse;
    users: UserResponse[];
}

export interface IUIState {
    showingAddUsers: boolean;
    allowHomeNavigate: boolean;
    working: number;
    workingHistory: string[];
    redirectAfterLogin: UIRedirectInfo | undefined;
    modifyWorkspaceUserState: ModifyWorkspaceUsersState | undefined;
    lastUserInteractionUpdateTime: number;   // milliseconds since epoch
    uiStateUpdateTicker: number;  // for periodic state updates, this is simply incremented every time the app timer fires. Anything 
                                  // needing to periodically update can useEffect with that as a dependency.
    cookieSessionData?: CookieSessionData;   // Extracted from the AULS_INFO cookie, contains information about
                                             // when the session will expire. 
}

const defaultState: IUIState = {
    showingAddUsers: false,
    allowHomeNavigate: true,
    working: 0,
    workingHistory: [],
    redirectAfterLogin: undefined,
    modifyWorkspaceUserState: undefined,
    lastUserInteractionUpdateTime: Date.now(),
    uiStateUpdateTicker: 1,
}

const generateNonce = (): string => {
    const randBuffer = randomBytes(24);
    return randBuffer.toString('base64');
}

export const reducer: Reducer<IUIState, KnownAction> = (state: IUIState | undefined, action: KnownAction): IUIState => {
    let newState: IUIState | undefined = undefined;

    switch (action.type) {
        case UIStateActions.SetAddUsers: {
            newState = {
                ...(state ? state : defaultState),
                showingAddUsers: action.isActive,
            };
            break;
        }
        case UIStateActions.SetHomeNavigate: {
            newState = {
                ...(state ? state : defaultState),
                allowHomeNavigate: action.allow,
            }
            break;
        }
        case UIStateActions.SetRedirectAfterLogin: {
            newState = {
                ...(state ? state : defaultState),
            }
            if (!!action.path) {
                newState.redirectAfterLogin = {
                    path: action.path,
                    nonce: generateNonce(),
                    searchParams: action.searchParams
                }
            } else {
                newState.redirectAfterLogin = undefined;
            }
            break;
        }
        case UIStateActions.ChangePortalBusy: {
            newState = {
                ...(state ? state : defaultState),
            }
            newState.working = newState.working + action.increment;

            if (newState.working < 0) {
                LogException(
                    `Attempting to set portal working state less than 0`,
                    new Error(`Attempting to set portal working state less than 0`),
                    {
                        increment: action.increment,
                        source: action.source,
                    }
                );
                newState.working = 0;
            }
            newState.workingHistory.push(`${action.increment > 0 ? 'push' : 'pop'}:${action.increment}:newState ${newState.working}:${action.source}`);
            // Keep the list no greater than 50.  That should be plenty.
            if (newState.workingHistory.length > 50) {
                newState.workingHistory = newState.workingHistory.slice(newState.workingHistory.length - 50);
            }

            break;
        }
        case UIStateActions.SetModifyWorkspaceUsersState: {
            newState = {
                ...(state ? state : defaultState),
            }
            if (!!action.data) {
                newState.modifyWorkspaceUserState = {
                    ...action.data,
                }
            } else {
                newState.modifyWorkspaceUserState = undefined;
            }

            break;
        }
        case UIStateActions.UpdateLastUserInteraction: {
            newState = {
                ...(state ? state : defaultState),
                lastUserInteractionUpdateTime: Date.now(),
            }
            break;
        }
        case UIStateActions.UpdateUIStateTimer: {
            newState = {
                ...(state ? state : defaultState),
                cookieSessionData: action.cookieSessionData,
            }
            newState.uiStateUpdateTicker++;

            break;
        }
    }

    if (newState) {
        return newState;
    } else if (state) {
        return state;
    } else {
        let defaultCopy: IUIState = {
            ...defaultState,
        };
        return defaultCopy;
    }
}