import * as React from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { LogException, LogMessage, SeverityLevel } from '../Utils/Logging';

import {
    Container,
    Col,
    Nav,
    NavItem,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap';

import {
    Tooltip
} from '@mui/material';

import { WorkspaceSubmissions } from './WorkspaceSubmissions/WorkspaceSubmissions';

import { breadCrumbContent, pathConstants } from '../Utils/Constants';

import { UserResponse } from '../Models/Api/strongbox.financialportal';

import { ConfirmModal } from './ConfirmModal/ConfirmModal';

import {
    actionCreators as appSettingsActions,
    GetShowDeletedSubmissions
} from '../Store/AppSettings';
import {
    actionCreators as SubmissionHistoryActions,
    GetCurrentRange,
    MapSubmissionSearchResult
} from '../Store/SubmissionHistory';
import { SubmissionHistoryRange } from '../Utils/SubmissionHistoryRange';
import { SearchSubmissions } from '../Services/SubmissionService'

import { BrandConfig, GetBrandConfig } from '../Store/Tenant';
import { GetLoggedInUser, GetUsers, LoadAllUsers } from '../Store/User';
import { GetRunningJobs, RunningImport } from '../Store/RunningImports';
import {
    actionCreators as ImportFinancialsActions,
} from '../Store/ImportFinancials';
import {
    actionCreators as UIStateActions,
    GetPortalBusyState,
    GetRefreshStateTicker,
} from '../Store/UIState';
import {
    BulkAddWorkspaceUsers,
    BulkRemoveAllWorkspaceUsers,
    ChangeWorkspaceUserStatus,
    GetDetailedWorkspaces,
    GetWorkspaceInfo,
    actionCreators as WorkspacesActions,
    WorkspaceWithMeta,
    LoadWorkspaceDetails,
    LoadWorkspaceConnections,
    WorkspacePlusUser,
    WorkspacePageInfo,
    SaveWorkspaceUsers
} from '../Store/Workspaces';
import {
    actionCreators as RunningJobsActions
} from '../Store/RunningImports';

import { ApplicationState } from '../Store';
import { ErrorBanner, ErrorState } from './ErrorBanner/ErrorBanner';

import { UpdateWorkspaceDetails } from '../Services/WorkspaceService';

import { BusyWindowWrapper } from './LoadingMessageWithIndicator/BusyWindowWrapper';

import { WorkspaceDetailsTab, WorkspaceEditContent } from './WorkspaceDetailsPages/Details/WorkspaceDetailsTab';
import { WorkspaceConnections } from './WorkspaceDetailsPages/Connections/WorkspaceConnections';

import { BuildQueryString } from '../Utils/WorkspaceRouteInformation';

import { BreadCrumbs } from './BreadCrumbs/BreadCrumbs';
import { GetUnsafeContentMessage, InputStringContainsDangerousContent } from '../Utils/FormUtils';

type InjectedReduxState = {
    showDeletedSubmissions: boolean;
    workspaces: WorkspaceWithMeta[];
    workspaceDetailsWithUsers: WorkspacePlusUser[];
    allUsers: UserResponse[];
    currentPageInfo: WorkspacePageInfo | undefined;
    loggedInUser?: UserResponse;
    runningImports: RunningImport[];
    portalBusy: boolean;
    submissionHistoryRange: SubmissionHistoryRange;
    brandConfig: BrandConfig;
    uiRefreshTicker: number;
};

type InjectedActionCreators =
    typeof appSettingsActions &
    typeof ImportFinancialsActions &
    typeof WorkspacesActions &
    typeof SubmissionHistoryActions &
    typeof UIStateActions &
    typeof RunningJobsActions;

type WorkspaceDetailsProps = {};

type Props = WorkspaceDetailsProps & InjectedActionCreators & InjectedReduxState;

type TabInfo = {
    title: string;
    type: 'connectionlist' | 'submissionlist' | 'workspacedetails' | 'deletedsubmissions';
    path: string;
}

const WorkspaceDetailsComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        AddLoadedWorkspace,
        brandConfig,
        allUsers,
        workspaceDetailsWithUsers,
        workspaces,
        ResetWorkspaceUsers,
        loggedInUser,
        OverrideUserWorkspaceAccess,
        runningImports,
        portalBusy,
        SetPortalWorking,
        SetPortalIdle,
        submissionHistoryRange,
        SetCurrentResults,
        ListPendingJobs,
        uiRefreshTicker,
    } = props;

    const location = useLocation();
    const navigate = useNavigate();

    // parameter name, i.e. 'workspaceId' should match the value in routeConstants.workspaceIdParam 
    const {
        workspaceId
    } = useParams();

    const [editWorkspaceError, setEditWorkspaceError] = React.useState<string | undefined>(undefined);

    const [errorState, setErrorState] = React.useState<ErrorState | undefined>(undefined);
    const [tabs, setTabs] = React.useState<TabInfo[]>([]);
    const [matchedPath, setMatchedPath] = React.useState<string>('');

    const [detailsAreModified, setDetailsAreModified] = React.useState<boolean>(false);

    const [confirmUserRemove, setConfirmUserRemove] = React.useState<UserResponse | undefined>(undefined);
    const [wsDisplayName, setWsDisplayName] = React.useState<string>('');

    const [initEditContent, setInitEditContent] = React.useState<boolean>(true);
    const [editContent, setEditContent] = React.useState<WorkspaceEditContent>({
        name: '',
        nameIsBad: false,
        engagementCode: '',
        engagementCodeIsBad: false,
    });

    const activeWorkspaceDetails = React.useMemo(() => {
        const result = workspaceDetailsWithUsers.find(dets => dets.id === workspaceId);

        if (result && result.details) {

            if (initEditContent) {
                setEditContent({
                    name: result.details.displayName,
                    nameIsBad: false,
                    engagementCode: result.details.engagementCode || '',
                    engagementCodeIsBad: false
                });
                setWsDisplayName(result.details.displayName);

                setInitEditContent(false);
            }
        }

        return result;

        // These are actually the only dependencies I want
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workspaceDetailsWithUsers, workspaceId])

    React.useEffect(() => {
        ListPendingJobs(false);
        // These are the dependencies I actually want.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uiRefreshTicker]);

    const getWorkspaceDetails = async (workspaceId: string): Promise<void> => {
        LogMessage(
            `Retrieving workspace details for id: ${workspaceId}`,
            SeverityLevel.Information,
            {
                workspaceId,
            }
        );

        SetPortalWorking('WorkspaceDetails:getWorkspaceDetails');

        // If we are showing any submissions that have submissionRequest information attached to
        // them we'll need users loaded so we can convert a user id to a user.  Users aren't likely to
        // change very much, this call to LoadAllUsers is required if, e.g. the user refreshes the
        // page and the redux store was cleared.  If we have any users already than we probably don't need
        // to call it and it's a fairly expensive operation.  Also, when users are added, the list of users
        // is refreshed so it's likely up to date if there are any in the store.
        let loadingUsers = false;
        if (allUsers.length <= 0) {
            loadingUsers = true;
        }

        try {
            let usersForLoading = allUsers;

            if (loadingUsers) {
                usersForLoading = await LoadAllUsers();
            }
            const workspaceDetails = await LoadWorkspaceDetails(workspaceId, usersForLoading);
            if (!workspaceDetails) {
                LogException(
                    `Requested workspace ID not found, this shouldn't happen`,
                    new Error(`Requested workspace ID not found, this shouldn't happen`),
                    {
                        workspaceId
                    }
                );

                setErrorState({
                    summaryMessage: `Unable to find requested workspace.`,
                });
            } else {
                const workspaceInfo = workspaceDetails.entityDetails;

                AddLoadedWorkspace(workspaceInfo);

                await LoadWorkspaceConnections(workspaceId);
            }
        } catch (exception) {
            LogException(
                `Failed retrieving details for a workspace`,
                exception,
                {
                    workspaceId
                }
            );

            console.error('Exception during getWorkspaceDetails');
            console.error(exception);

            setErrorState({
                summaryMessage: `Unable to retrieve workspace details at this time.`,
                extraInformation: `${exception.message} ${(!!exception.errorMessage && exception.errorMessage + '.') || ''}`,
            });
        } finally {
            SetPortalIdle('WorkspaceDetails:getWorkspaceDetails');
        }
    }

    React.useEffect(() => {
        const extractParameters = (): void => {
            if (!(workspaceId)) {
                LogMessage(
                    `Unexpected state, workspace id is expected in path ${window.location.pathname}, redirecting to workspace list`,
                    SeverityLevel.Warning,
                    {
                        location: window.location
                    }
                );

                console.error('Unexpected state: WorkspaceDetails is missing the ID  in url, redirecting to Workspace list');
                navigate(pathConstants.home);
            } else {
                getWorkspaceDetails(workspaceId);
            }
        }

        extractParameters();
        let newTabs: TabInfo[] = [
            {
                title: 'Past Data Collections',
                type: 'submissionlist',
                path: pathConstants.workspaceDetailsSubmissionList,
            },
            {
                title: 'Manage Active Connections',
                type: 'connectionlist',
                path: pathConstants.workspaceDetailsConnections,
            }
        ];


        // Administrative tabs added after the functional tabs. Makes more sense to have, e.g. user 
        // management come after overview

        newTabs = newTabs.concat([
            {
                title: 'Workspace Details',
                type: 'workspacedetails',
                path: pathConstants.workspaceDetailsUsers,
            }
        ]);

        setTabs(newTabs);

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        // Because the name is unescaped we need to re-escape it for this.
        const pathToMatch = location.pathname.replace(`/${workspaceId}`, '');
        setMatchedPath(pathToMatch);
    }, [location.pathname, workspaceId]);

    const buildErrorContent = (): React.ReactElement => {
        if (!errorState) {
            return (<></>);
        }

        let onActionButton = () => {
            setErrorState(undefined);
        }

        return (
            <ErrorBanner
                errorState={errorState}
                onDefaultActionButton={onActionButton}
            />
        );
    }

    const workspaceActionButtons = (): React.ReactElement => {
        // Workspace name in use is used so it doens't look weird while
        // loading
        if (!!workspaceId) {
            return (
                <div className={'portal-header-region'}>
                    <Tooltip title={'Import financials'}>
                        <span>
                            <button
                                className={'small text'}
                                style={{
                                    marginLeft: '15px',
                                }}
                                onClick={() => {
                                    props.SetImportCustomerFinancialsActive(
                                        true,
                                        {
                                            workspaceId: workspaceId,
                                            workspaceName: wsDisplayName,
                                        }
                                    );
                                }}
                                disabled={portalBusy}
                            >
                                New Data Collection
                            </button>
                        </span>
                    </Tooltip>
                    <Tooltip title={'Refresh this page'}>
                        <span>
                            <button
                                className={'small text'}
                                onClick={async () => {
                                    try {
                                        await getWorkspaceDetails(workspaceId);
                                        if (!!(submissionHistoryRange.start && submissionHistoryRange.end)) {
                                            const searchResults = await SearchSubmissions(
                                                submissionHistoryRange.start,
                                                submissionHistoryRange.end,
                                                workspaceId,
                                                true
                                            );
                                            SetCurrentResults(searchResults.map(ssr => MapSubmissionSearchResult(ssr)), workspaceId);
                                        }
                                    } catch (exception) {
                                        LogException(
                                            'Error refreshing workspaces after user pressed the refresh button in workspace details',
                                            exception,
                                            {
                                                workspaceId
                                            }
                                        );
                                    }
                                }}
                                disabled={portalBusy}
                                style={{
                                    marginLeft: '10px',
                                }}
                            >
                                Refresh
                            </button>
                        </span>
                    </Tooltip>
                </div>
              )
        } else {
            return (<div></div>);
        }
    }

    const workspaceName = (): React.ReactElement => {
        return (
            <>
                <div className={'control-header-content'}>
                    <h1>{wsDisplayName}</h1>
                </div>
            </>
        );
    }

    let activeTab = matchedPath;

    const showBusy = (): boolean => {
        return portalBusy || (!!activeWorkspaceDetails && activeWorkspaceDetails.loading);
    }

    const changeWorkspaceUserStatus = (user: UserResponse, included: boolean, overrideCheck?: boolean): void => {
        if (!activeWorkspaceDetails) {
            return;
        }

        if (overrideCheck !== true) {
            if ((!!loggedInUser && (loggedInUser.id === user.id)) && (!included)) {
                setConfirmUserRemove(loggedInUser);
                return;
            }
        }

        setDetailsAreModified(true);

        ChangeWorkspaceUserStatus(activeWorkspaceDetails.id, user, included);
    }

    async function ExecuteWorkspaceUpdate(): Promise<void> {
        if (!activeWorkspaceDetails) {
            return;
        }

        SetPortalWorking('WorkspaceDetails:saveUserChanges');

        try {
            await SaveWorkspaceUsers(activeWorkspaceDetails.id);

            // Override the state of the user to be able to view the workspace in the redux store
            // Also, check to see if the logged in user still has access to this workspace by 
            // looking to see if this user is still in the list of workspace users.   If
            // not then they can no longer be here, navigate home.
            if (!!loggedInUser) {
                const loggedInUserHasAccess = !!activeWorkspaceDetails.users.find(user => user.id === loggedInUser.id);
                OverrideUserWorkspaceAccess(activeWorkspaceDetails.id, loggedInUser.id, loggedInUserHasAccess);
                if (!loggedInUserHasAccess) {
                    navigate(pathConstants.home);
                }
            }

            if (detailsAreModified) {
                // EC could be an empty string so set undefined if it's empty or already undefined
                const newEngagementCode = !!editContent.engagementCode ? editContent.engagementCode : undefined;

                UpdateWorkspaceDetails(
                    activeWorkspaceDetails.id,
                    editContent.name,
                    newEngagementCode
                );

                props.UpdateWorkspaceName(activeWorkspaceDetails.id, editContent.name);
                props.UpdateLoadedWorkspaceDetails(activeWorkspaceDetails.id, editContent.name, editContent.engagementCode);

                setWsDisplayName(editContent.name);

                setDetailsAreModified(false);
            }
        } catch (exception) {
            LogException(
                `Failed to save user changes for workspace ${activeWorkspaceDetails.id}`,
                exception,
                {
                    workspaceId: activeWorkspaceDetails.id
                }
            );

            setErrorState({
                summaryMessage: `There has been an error saving current user state for this workspace`,
                extraInformation: exception.toString(),
            });
        } finally {
            SetPortalIdle('WorkspaceDetails:saveUserChanges');
        }
    }

    const saveUserChanges = (): void => {
        if (!activeWorkspaceDetails) {
            return;
        }

        if (InputStringContainsDangerousContent(editContent.name)) {
            setEditWorkspaceError(GetUnsafeContentMessage('workspace name'));
            setEditContent({
                ...editContent,
                nameIsBad: true
            });
            return;
        }
        if (InputStringContainsDangerousContent(editContent.engagementCode)) {
            setEditWorkspaceError(GetUnsafeContentMessage('workspace engagement code'));
            setEditContent({
                ...editContent,
                engagementCodeIsBad: true
            });
            return;
        }

        ExecuteWorkspaceUpdate();
    }

    const resetWorkspace = (): void => {
        if (!!activeWorkspaceDetails) {
            ResetWorkspaceUsers(activeWorkspaceDetails.id);
            setDetailsAreModified(false);
            setEditContent({
                name: activeWorkspaceDetails?.details?.displayName || '',
                nameIsBad: false,
                engagementCode: activeWorkspaceDetails?.details?.engagementCode || '',
                engagementCodeIsBad: false
            });
        } 
    }

    const selectAllUsers = (selected: boolean): void => {
        if (!(activeWorkspaceDetails && allUsers)) {
            return;
        }

        if (selected) {
            const toInclude = allUsers.filter(user => {
                return !!activeWorkspaceDetails.users.find(existingUser => existingUser.id === user.id) ? undefined : user;
            });

            BulkAddWorkspaceUsers(activeWorkspaceDetails.id, toInclude);
        } else {
            let toExclude: UserResponse[] | undefined = allUsers.filter(user => {
                if (!loggedInUser) {
                    return undefined;
                }
                return loggedInUser.id === user.id ? user : undefined;
            });
            if (toExclude.length <= 0) {
                toExclude = undefined;
            }
            BulkRemoveAllWorkspaceUsers(activeWorkspaceDetails.id, toExclude);
        }

        setDetailsAreModified(true);
    }

    const runningJobDismissed = (financialRecordId: string, workspaceId: string, fromNav: boolean): void => {
        // This should be a sanity check, we don't allow these to be set to null.
        // In practice at the moment, we don't need to check fromNav here as it will never be true 
        // from this page but if that changes, then the navigation away from the page would cause 
        // an unpredicatable failure of SearchSubmissions

        if ((submissionHistoryRange.start === null) || (submissionHistoryRange.end === null) || (fromNav)) {
            return;
        }

        SearchSubmissions(
            submissionHistoryRange.start,
            submissionHistoryRange.end,
            workspaceId, 
            true,
        )
            .then(searchResults => {
                SetCurrentResults(searchResults.map(ssr => MapSubmissionSearchResult(ssr)), workspaceId);
            });
    }

    const getTabContent = (): React.ReactElement => {
        let tabContent: React.ReactElement = (<></>);

        const wsDetails = workspaces.find(ws => ws.id === workspaceId);
        if (!wsDetails) {
            return tabContent;
        }

        switch (matchedPath) {
            case pathConstants.workspaceDetailsSubmissionList:
                tabContent = (
                    <TabPane key={'submissionListTab'} tabId={pathConstants.workspaceDetailsSubmissionList}>
                        <Row>
                            <Col xs={'12'}>
                                <BusyWindowWrapper
                                    portalBusy={props.portalBusy}
                                >
                                    <WorkspaceSubmissions
                                        onRunningJobDismissed={runningJobDismissed}
                                        workspaceDetails={wsDetails}
                                    />
                                </BusyWindowWrapper>
                            </Col>
                        </Row>
                    </TabPane>
                );
                break;
            case pathConstants.workspaceDetailsConnections:
                tabContent = (
                    <TabPane key={'connectionsListTab'} tabId={pathConstants.workspaceDetailsConnections}>
                        <Row>
                            <Col xs={'12'}>
                                <BusyWindowWrapper
                                    portalBusy={props.portalBusy}
                                >
                                    <WorkspaceConnections
                                        connections={activeWorkspaceDetails?.connections?.connections || []}
                                        capabilities={activeWorkspaceDetails?.details?.workspaceUserCapabilities || []}
                                        workspaceId={workspaceId || ''}
                                        workspaceDisplayName={wsDisplayName}
                                        runningImports={runningImports}
                                        onRunningJobDismiss={runningJobDismissed}
                                        brandConfig={brandConfig}
                                    />
                                </BusyWindowWrapper>
                            </Col>
                        </Row>
                    </TabPane>
                )
                break;
            case pathConstants.workspaceDetailsUsers:
                tabContent = (
                    <TabPane key={'detailsTab'} tabId={pathConstants.workspaceDetailsUsers}>
                        <Row>
                            <Col xs={'12'}>
                                <BusyWindowWrapper
                                    portalBusy={props.portalBusy}
                                >
                                    <WorkspaceDetailsTab
                                        workspaceName={wsDisplayName}
                                        workspaceId={workspaceId || ''}
                                        workspaceDetails={activeWorkspaceDetails}
                                        allUsers={allUsers}
                                        onUserStatusChange={changeWorkspaceUserStatus}
                                        onSaveChanges={saveUserChanges}
                                        onReset={resetWorkspace}
                                        onSelectAll={selectAllUsers}
                                        detailsAreModified={detailsAreModified}
                                        loggedInUser={loggedInUser}
                                        onWorkspaceDetailsChange={(newContent) => {
                                            setEditContent({
                                                ...newContent,
                                                nameIsBad: false,
                                                engagementCodeIsBad: false
                                            });
                                            setDetailsAreModified(true);
                                            setEditWorkspaceError(undefined);
                                        }}
                                        editContent={editContent}
                                        editErrorContent={editWorkspaceError}
                                        disableEdit={portalBusy}
                                    />
                                </BusyWindowWrapper>
                            </Col>
                        </Row>
                    </TabPane>
                );
                break;
    
/*
            default:
                console.error(`Unknown path WorkspaceDetails ${location.pathname}`);
                return (<Navigate to={pathConstants.notFound} />)
*/
        }

        return tabContent;
    }

    return (
        <div style={{ position: 'absolute', width: '100%' }} className={`free-content-region`} >
            <div style={{ height: '100%' }} className={`contained-content control-region control-region-lender`} >
                {buildErrorContent()}
                {
                    !!confirmUserRemove && (
                        <ConfirmModal
                            msg={`You are removing access to this workspace for yourself (${confirmUserRemove.displayName}). Are you sure you want to do this?`}
                            title={'Warning'}
                            onTerminalButton={(yes?: boolean) => {
                                if (!!yes) {
                                    changeWorkspaceUserStatus(confirmUserRemove, false, true);
                                }
                                setConfirmUserRemove(undefined);
                            }}
                        />
                    )
                }
                <Container style={{ marginBottom: '30px' }} fluid>
                    <Row className={'spaced-row-centered'}>
                        <Col xs={2}>
                            <BreadCrumbs
                                crumbs={[
                                    {
                                        content: breadCrumbContent.workspaceList,
                                        link: pathConstants.home,
                                    }
                                ]}
                            />
                        </Col>
                        <Col>
                            {workspaceName()}
                        </Col>
                        <Col>
                            {workspaceActionButtons()}
                        </Col>
                    </Row>
                </Container>
                <Nav
                    disabled={showBusy()}
                    tabs
                    className={`portal-tabs tabs-right`}
                >
                    {tabs.map((tabInfo, index) => {
                        let navItemClass = `portal-nav-item-inactive`;
                        let navLinkClass = '';
                        if (matchedPath === tabInfo.path) {
                            navItemClass = `portal-nav-item-active`;
                            navLinkClass = 'portal-nav-link-active';
                        }

                        return (
                            <NavItem
                                key={`nav-workspacedetails-${tabInfo.type}`}
                                className={`portal-nav-item ${navItemClass}`}
                            >
                                <Link
                                    className={`portal-nav-link ${navLinkClass}`}
                                    to={`${tabInfo.path}/${BuildQueryString({
                                        workspaceId: workspaceId || '',
                                        workspaceName: wsDisplayName
                                    })}`}
                                >
                                    {tabInfo.title}
                                </Link>
                            </NavItem>
                        )
                    })}
                </Nav>
                <TabContent
                    activeTab={activeTab}
                    className={'portal-tab-content portal-tab-content-raised'}
                >
                    {getTabContent()}
                </TabContent>
            </div>
        </div>
    );
}

export const WorkspaceDetails = connect<InjectedReduxState, InjectedActionCreators, WorkspaceDetailsProps, ApplicationState>(
    (appState: ApplicationState) => {
        const workspacesWithMeta = GetWorkspaceInfo(appState);

        const result = {
            showDeletedSubmissions: GetShowDeletedSubmissions(appState),
            workspaces: !!workspacesWithMeta ? workspacesWithMeta.workspaces : [],
            workspaceDetailsWithUsers: GetDetailedWorkspaces(appState),
            allUsers: GetUsers(appState),
            currentPageInfo: workspacesWithMeta?.pageInfo,
            loggedInUser: GetLoggedInUser(appState),
            runningImports: GetRunningJobs(appState),
            portalBusy: GetPortalBusyState(appState),
            submissionHistoryRange: GetCurrentRange(appState, 'workspace'),
            brandConfig: GetBrandConfig(appState),
            uiRefreshTicker: GetRefreshStateTicker(appState),
        };

        return result;
    },
    dispatch => bindActionCreators(
        {
            ...appSettingsActions,
            ...ImportFinancialsActions,
            ...WorkspacesActions,
            ...SubmissionHistoryActions,
            ...UIStateActions,
            ...RunningJobsActions
        },
        dispatch
    )
)(WorkspaceDetailsComponent);
