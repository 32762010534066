import { BasicPost } from './SimpleFetchCall';
import { Auth0DecodedHash, AuthOptions, WebAuth } from 'auth0-js';

interface IAuthUserMetadata {
    firstName?: string;
    lastName?: string;
    lastInvitationToken?: string;
}

export interface IAuthProfile {
    email_verified: boolean;
    email: string;
    family_name: string;
    given_name: string;
    name: string;
    picture: string;
    signup_required: boolean;
    sub: string;
    user_id: string;
    // OIDC conformant namespace for custom claim
    // https://auth0.com/docs/api-auth/tutorials/adoption/scope-custom-claims#custom-claims
    ['https://cashflowtool.com/user_metadata']: IAuthUserMetadata;
}

export interface IAuth0LoggedInUser {
    auth: Auth0DecodedHash;
    profile: IAuthProfile; // This may become optional once abstracted to commonlibrary
}

export type AuthServiceOptions = AuthOptions;
export type AuthResult = Auth0DecodedHash;
export type AuthError = {
    error?: string;
    error_description?: string;
};

class AuthService {
    private _webAuth: WebAuth;
    private _initializedOptions: AuthOptions;

    private _defaultScope = 'openid name email user_metadata app_metadata picture profile';
    private _defaultWebAuthOptions: Partial<AuthOptions> = {
        responseType: 'id_token token',
        scope: this._defaultScope,
    };

    /**
     * Instantiate new AuthService with webauth instance.
     */
    public constructor(options: AuthOptions) {
        this._initializedOptions = {
            ...this._defaultWebAuthOptions,
            ...options,
        };
        this._webAuth = new WebAuth(this._initializedOptions);
    }

    /**
     * Cross origin verification for use in places where third party cookies have limited to no (intermittent?) support 
     */
    public CrossOriginVerification = (): void => {
        const webAuth = this.GetWebAuth();
        (webAuth as any).crossOriginVerification();
    }

    /**
     * Attempt to fetch AuthTokens from local storage. Probably throw error if expire time hit, expect
     * devs to catch and redirect to login
     */
    public FetchUserProfile = (authResult: Auth0DecodedHash): Promise<IAuthProfile> => {
        return new Promise<IAuthProfile>((resolve, reject): void => {
            // @ts-ignore
            this._webAuth.client.userInfo(authResult.accessToken, (error, profile: any) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(profile);
                }
            });
        });
    };

    /**
     * Trigger the change password email for given user.
     * @param email The user to send a password reset email to
     * @param connection The name of the database to target inside auth0
     */
    public ForgotPassword = (email: string, connection = 'Username-Password-Authentication'): Promise<string> => {
        return new Promise<string>((resolve, reject): Promise<string> => {
            const webAuth = this.GetWebAuth();
            webAuth.changePassword(
                {
                    connection,
                    email,
                },
                // @ts-ignore
                (error, response) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(response);
                    }
                }
            );

            // @ts-ignore
            return;
        });
    }

    /**
     * Retrieve the current instance of the lock. Throws an error if the lock is not available.
     */
    public GetWebAuth = (): WebAuth => {
        if (this._webAuth) {
            return this._webAuth;
        } else {
            throw new Error('Auth0 WebAuth not available; intialize webauth before attempting to get instance');
        }
    }

    /**
     * Start Auth0 login flow with username and password. Async, because error prevents redirect to callback url
     * @param email The user's email address
     * @param email The user's password
     * @param scope OAuth claims to attach to this login cycle. Claims attached will be fulfilled against the callback url
     */
    public Login = (email: string, password: string, state?: {}, scope: string = this._defaultScope): Promise<void> => {
        return new Promise<void>(async (resolve, reject): Promise<void> => {
            const webAuth = this.GetWebAuth();
            const additionalParams = state ? { state: JSON.stringify(state) } : {};

            webAuth.login(
                {
                    realm: 'Username-Password-Authentication',
                    email,
                    password,
                    scope,
                    ...additionalParams,
                } as any, // Incorrect typings for auth0-js 9 
                // @ts-ignore
                (error, result) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(result);
                    }
                }
            );
        });
    }

    /**
     * Login with social connection. Use the oauth connection string to identify which service. This creates an auth0 account if one
     * does not exist, so no additional method for signup is needed.
     */
    // @ts-ignore
    public LoginSocial = <DeserializedState>(provider, state?: DeserializedState): void => {
        const webAuth = this.GetWebAuth();
        const additionalParams = state ? { state: JSON.stringify(state) } : {};

        webAuth.authorize(
            {
                connection: provider,
                state: JSON.stringify(state),
                ...additionalParams,
            }
        );
    }

    /**
     * Flush the stored profile and auth information we have out of the store
     */
    public Logout = (): void => {
        localStorage.clear();
    };

    /**
     * Authentication hook that captures the authresult and profile data, as well as deserializes the additional state if available.
     * Note that the deserialized state should be partial coming in because it may not exist.
     */
    public OnAuthenticated = async <DeserializedState>(
        authResult: Auth0DecodedHash,
        processProfile?: (authResult: Auth0DecodedHash, profile: IAuthProfile) => Promise<IAuthProfile>
    ): Promise<IAuth0LoggedInUser & DeserializedState> => {
        let profile = await this.FetchUserProfile(authResult);

        profile = processProfile ? await processProfile(authResult, profile) : profile;

        AuthService.SetUserProfile(profile);
        AuthService.SetAuthResult(authResult);

        const loggedInUser = <IAuth0LoggedInUser & DeserializedState>{
            auth: authResult,
            profile,
        };

        try {
            if (authResult.state) {
                const authState = <DeserializedState>JSON.parse(authResult.state);

                // Justification: Object spread doesn't work on generics. Yeah. Seriously.
                /*tslint:disable-next-line:prefer-object-spread*/
                return Object.assign({}, loggedInUser, authState);
            }
        } catch (error) {
            // Suppress, because state is optional
        } finally {
            // we don't need the extra state, only if it's available, so we suppress this
            return loggedInUser;
        }
    };

    /**
     * Attempts to renew the current user session. Uses the SSO session stored in cookies by Auth0.
     */
    public Renew = (): Promise<IAuth0LoggedInUser> => {
        return new Promise((resolve, reject): void => {
            this._webAuth.checkSession(
                {},
                // @ts-ignore
                (err, response) => {
                    if (err) {
                        reject(err);

                        return;
                    }

                    this.OnAuthenticated(response).then(resolve);
                }
            );
        });
    }

    /**
     * Signup against auth0 with username or password. Async, because if this initial calls back with an error, the redirect to
     * callback will not occur.
     * @param email The new user's email
     * @param password The new user's password
     * @param connection The name of the database to target inside auth0
     */
    public Signup = (email: string, password: string, state?: {}, connection = 'Username-Password-Authentication'): Promise<void> => {
        return new Promise<void>(async (resolve, reject): Promise<void> => {
            const webAuth = this.GetWebAuth();
            const additionalParams = state ? { state: JSON.stringify(state) } : {};

            webAuth.redirect.signupAndLogin(
                {
                    connection,
                    email,
                    password,
                    ...additionalParams,
                } as any, // Incorrect typings for auth0-js 9 
                // @ts-ignore
                (error, response) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve();
                    }
                }
            );
        });
    }

    /**
     * Attempts to get a logged in user. Error will bubble through for routing logic on failure
     */
    public static GetLoggedInUser = (): IAuth0LoggedInUser => {
        return {
            auth: AuthService.GetAuthResult(),
            profile: AuthService.GetUserProfile(),
        };
    };

    private static GetAuthResult = (): Auth0DecodedHash => {
        try {
            return <Auth0DecodedHash>JSON.parse(localStorage.getItem('authResult') || ''); // These are now complaining about undefined
        } catch (error) {
            throw new Error('Authentication data not found, must re-login');
        }
    };

    private static GetUserProfile = (): IAuthProfile => {
        try {
            return <IAuthProfile>JSON.parse(localStorage.getItem('profile') || ''); // These are now complaining about undefined
        } catch (error) {
            throw new Error('Profile not available locally, must re-login');
        }
    };

    private static SetAuthResult = (authResult: Auth0DecodedHash): void => {
        localStorage.setItem('authResult', JSON.stringify(authResult));
    };

    private static SetUserProfile = (profile: IAuthProfile): void => {
        localStorage.setItem('profile', JSON.stringify(profile));
    };
}

export async function Ping(): Promise<void> {
    return await BasicPost(
        `/api/auth/ping`,
        'PingBackend'
    );
}

export default AuthService;
