import {
    UIStateActions,
    ISetAddUsers,
    IAllowHomeNavigate,
    IChangePortalBusy,
    ISetRedirectAfterLogin,
    ISetModifyWorkspaceUsersState,
    IUpdateUIStateTimer,
    IUpdateLastUserInteraction,
} from './Actions';
import { ModifyWorkspaceUsersState, UIRedirectSearchParams } from './Reducer';
import { CookieSessionData } from '../../Models/CookieSessionData';

function SetAddUsers(isActive: boolean): ISetAddUsers {
    return {
        type: UIStateActions.SetAddUsers,
        isActive,
    };
}

function AllowHomeNavigate(allow: boolean): IAllowHomeNavigate {
    return {
        type: UIStateActions.SetHomeNavigate,
        allow,
    }
}

function SetPortalWorking(source: string): IChangePortalBusy {
    return {
        type: UIStateActions.ChangePortalBusy,
        increment: 1,
        source
    };
}

function SetPortalIdle(source: string): IChangePortalBusy {
    return {
        type: UIStateActions.ChangePortalBusy,
        increment: -1,
        source
    };
}

function SetRedirectAfterLogin(path?: string, searchParams?: UIRedirectSearchParams): ISetRedirectAfterLogin {
    return {
        type: UIStateActions.SetRedirectAfterLogin,
        path,
        searchParams
    };
}

function SetModifyWorkspaceUsersState(data?: ModifyWorkspaceUsersState): ISetModifyWorkspaceUsersState {
    return {
        type: UIStateActions.SetModifyWorkspaceUsersState,
        data,
    }    
}

function UpdateLastUserInteraction(): IUpdateLastUserInteraction {
    return {
        type: UIStateActions.UpdateLastUserInteraction
    }
}

function UpdateUIStateTimer(cookieSessionData: CookieSessionData): IUpdateUIStateTimer {
    return {
        type: UIStateActions.UpdateUIStateTimer,
        cookieSessionData,
    }
}

export const actionCreators = {
    SetAddUsers: SetAddUsers,
    SetRedirectAfterLogin: SetRedirectAfterLogin,
    SetPortalWorking: SetPortalWorking,
    SetPortalIdle: SetPortalIdle,
    AllowHomeNavigate: AllowHomeNavigate,
    SetModifyWorkspaceUsersState: SetModifyWorkspaceUsersState,
    UpdateLastUserInteraction: UpdateLastUserInteraction,
    UpdateUIStateTimer: UpdateUIStateTimer,
};
