import * as React from 'react';

import { useCookies } from 'react-cookie';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { BorrowerPortal } from './components/BorrowerPortal/BorrowerPortal';
import { AuthorizedWindowWrapper } from './components/AuthorizedWindowWrapper';
import { AuthTimedOut } from './components/AuthTimedOut';
import { SlidingTimeout } from './components/SlidingTimeout/SlidingTimeout';
import { LenderHome } from './components/LenderHome/LenderHome';
import { Documents } from './components/BorrowerPortal/Documents';
import { LenderPortal } from './components/LenderPortal';
import { Linked } from './components/BorrowerPortal/Linked';
import { Login } from './components/Login';
import { PortalSubmission } from './components/BorrowerPortal/PortalSubmission';
import { Progress } from './components/BorrowerPortal/Progress';
import { ShareableLinkExpired } from './components/BorrowerPortal/ShareableLinkExpired';
import { ShareableLinkDeleted } from './components/BorrowerPortal/ShareableLinkDeleted';
import { WorkspaceDetails } from './components/WorkspaceDetails';
import { Wrapper } from './components/Wrapper';
import { EmailConfirm } from './components/BorrowerPortal/EmailConfirm';
import { DownloadUserCopy } from './components/BorrowerPortal/DownloadUserCopy';
import { PageNotFound } from './components/PageNotFound';
import { SettingsContainer } from './components/Settings/SettingsContainer';
import { UserSettingsContainer, UserSettingsSubPage } from './components/UserSettings/UserSettings';
import { TenantSelection } from './components/TenantSelection';
import { SubmissionDetails } from './components/SubmissionDetails/SubmissionDetails';
import { DataCollectionReport } from './components/DataCollectionReport/DataCollectionReport';
import { InitializeAppInsights, LoggingWrapper } from './Utils/Logging';
import { TenantDisabled } from './components/TenantDisabled/TenantDisabled';
import { BasicStrongboxContentContainer } from './components/BasicStrongboxContentContainer/BasicStrongboxContentContainer';

import * as AppSettings from './Store/AppSettings';
import { TenantActionCreators, GetBasicSettings, TenantBasicSettings } from './Store/Tenant';
import { actionCreators as UserActions } from './Store/User';

import './custom.css'
import { ApplicationState } from './Store';
import { IAppSettingsState } from './Store/AppSettings';
import { BorrowerActionCreators } from './Store/Borrower';
import { ImportHasStarted } from './Store/ImportFinancials';
import { AppCookieData } from './Models/CookieData';
import { GetTenantName } from './Store/Tenant';
import { actionCreators as dataCollectionParametersActions } from './Store/NewDataCollectionParameters';

import { pathConstants, routeConstants, AppDataCookieNames } from './Utils/Constants';
import { LogMessage, SeverityLevel } from './Utils/Logging';

interface PropArguments {
    redirectPath?: string;
    clearRedirectPath?: () => void;
}

interface ReduxStateProps {
    appSettings: IAppSettingsState | undefined;
    tenantSettings: TenantBasicSettings | undefined;
    importHasStarted: boolean;
    tenantName: string;
}

type ReduxDispatchProps = typeof AppSettings.actionCreators & typeof UserActions & typeof BorrowerActionCreators & typeof TenantActionCreators;

type ComponentProps = PropArguments & ReduxStateProps & ReduxDispatchProps;

// ts-ignore is necessary below.  This is the code that Segment provides for initializing the analytics component. Normally, you'd drop this in
// the <head> tag, i.e. it wouldn't be in Typescript.  Because we have the dynamic inclusion of the code based on whether analytics are enabled 
// or not, this is complicated with a compiled SPA. It is safe, if window.analytics is present on the application window, it won't be overwriten and 
// if it isn't present the correct object will be added to window.

function InitializeAnalytics(writeKey: string) {
    // @ts-ignore
    var analytics = window.analytics = window.analytics || []; if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.'); else {
        // @ts-ignore
        analytics.invoked = !0; analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on', 'addSourceMiddleware', 'addIntegrationMiddleware', 'setAnonymousId', 'addDestinationMiddleware']; analytics.factory = function (e) { return function () { var t = Array.prototype.slice.call(arguments); t.unshift(e); analytics.push(t); return analytics } }; for (var e = 0; e < analytics.methods.length; e++) { var key = analytics.methods[e]; analytics[key] = analytics.factory(key) } analytics.load = function (key, e) { var t = document.createElement('script'); t.type = 'text/javascript'; t.async = !0; t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'; var n = document.getElementsByTagName('script')[0]; n.parentNode.insertBefore(t, n); analytics._loadOptions = e }; analytics.SNIPPET_VERSION = '4.13.1';
        analytics.load(writeKey);
        analytics.page();
    }
}

const SubmissionDetailsWrapper: React.FC<{
    onPostLoginComplete: () => void,
    mainDivKey: string,
    hideFooter?: boolean
}> = (props): React.ReactElement => {
    return (
        <AuthorizedWindowWrapper mainDivKey={props.mainDivKey} onPostLoginComplete={props.onPostLoginComplete}>
            <LenderPortal
                hideFooter={props.hideFooter}
            >
                <SubmissionDetails />
            </LenderPortal>
        </AuthorizedWindowWrapper>
    );
}

const WorkspaceDetailsWrapper: React.FC<{
    onPostLoginComplete: () => void,
    mainDivKey: string,
}> = (props): React.ReactElement => {
    return (
        <AuthorizedWindowWrapper mainDivKey={props.mainDivKey} onPostLoginComplete={props.onPostLoginComplete}>
            <LenderPortal>
                <WorkspaceDetails />
            </LenderPortal>
        </AuthorizedWindowWrapper>
    );
}

function App(props: ComponentProps) {
    const location = window.location.pathname.toLowerCase();

    // use react-hook to register for changes to cookie carrying application settings.
    const [cookies] = useCookies(Object.values(AppDataCookieNames));

    // This will give us an array containing an object for each of the cookie names defined in AppDataCookieNames
    const allCookieAppData = Object.values(AppDataCookieNames).map(cookieName => cookies[cookieName]);

    let appData = {};

    // Now take each of the objects in allCookieAppData (an array) and spread those into a single object.
    allCookieAppData.forEach(cookieData => {
        appData = {
            ...appData,
            ...cookieData,
        };
    });

    const [mainDivKey, setMainDivKey] = React.useState<number>(1);

    const onPostLoginComplete = (): void => {
        setMainDivKey(mainDivKey + 1);
    }

    if (!!appData && props.appSettings && !props.appSettings.initialized) {
        const cookieData = appData as AppCookieData;

        // If the current path is the submission page and we're not initialized then we should
        // clear the saved submission ID from the redux store. 
        if (!!location && (location === pathConstants.submission)) {
            props.SetBorrowerSubmissionId('');
        }

        if (!!location && (location === pathConstants.submissionSansFileUpload)) {
            cookieData.disableAdditionalDocUpload = true; 
            cookieData.disableAnonymousDocUpload = true;   // The above takes precedence anyway but just for good measure.
        }

        props.Initialize({ ...cookieData });
        props.UsersInitialize(cookieData);

        if (cookieData!.segmentAnalyticsEnabled && !!cookieData.segmentAnalyticsWriteKey) {
            InitializeAnalytics(cookieData.segmentAnalyticsWriteKey);
        }

        if (!!cookieData.appInsightsInstrumentationKey) {
            InitializeAppInsights(
                cookieData.disableAppInsights,
                cookieData.appInsightsInstrumentationKey,
                props.tenantName
            );
        }

        dataCollectionParametersActions.InitializeFromURL(window.location, cookieData);
    } else {
        // Else if we're on the starting page and the UIState has ImportHasStarted set true, that
        // only happens when they get to the linked page so they have successfully started an import.
        // In that event we can safely clear the submission ID because it's likely they backspaced here
        // from the completion page after starting an import as opposed to above where they might
        // have navigated to the docs page and backspaced back to start an import.
        if ((!!location) &&
            (location === pathConstants.submission) &&
            (props.importHasStarted)
        ) {
            props.SetBorrowerSubmissionId('');
        }
    }

    if (!!props.redirectPath) {
        props.clearRedirectPath && props.clearRedirectPath();
        LogMessage(
            'Processing redirect after login',
            SeverityLevel.Information,
            {
                path: props.redirectPath
            }
        );
        return (
            <BrowserRouter>
                <Wrapper>
                    <Navigate to={props.redirectPath} />
                </Wrapper>
            </BrowserRouter>
        );
    }

    // props.tenantSettings can be undefined during load while they're being retrieved from blob storage.
    if (!props.tenantSettings) {
        return (
            <></>
        );
    } else if (!!props.tenantSettings && !!props.tenantSettings.redirections) {
        const redirect = props.tenantSettings.redirections[location];
        if (!!redirect) {
            return (
                <BrowserRouter>
                    <Wrapper>
                        <Navigate to={redirect} />
                    </Wrapper>
                </BrowserRouter>
            );
        }
    }

    // FYI.  The <Switch> line below was formerly <Layout> from Layout.tsx.   That made the connect page
    // broken and this fixes that.

    return (
        <LoggingWrapper>
            <BrowserRouter>
                <Wrapper>
                    <Routes>
                        <Route path='/' element={
                            <LenderPortal
                                hideMenu={true}
                                hideLoggedInUser={true}
                            >
                                <Login />
                            </LenderPortal>
                        } />
                        <Route
                            path={'/businesses'}
                            element={
                                <Navigate to={pathConstants.home} />
                            }
                        />
                        <Route
                            path={'/home'}
                            element={
                                <Navigate to={pathConstants.home} />
                            }
                        />
                        <Route
                            path={routeConstants.home}
                            element={
                                <AuthorizedWindowWrapper
                                    mainDivKey={mainDivKey.toString()}
                                    onPostLoginComplete={onPostLoginComplete}
                                >
                                    <LenderPortal>
                                        <LenderHome />
                                    </LenderPortal>
                                </AuthorizedWindowWrapper>
                            }
                        />
                        <Route path={routeConstants.workspaceSubmissionDetails}>
                            <Route path={routeConstants.workspaceSubmissionDetailsOverview}>
                                <Route
                                    path={`${routeConstants.workspaceIdParam}/${routeConstants.submissionIdParam}`}
                                    element={
                                        <SubmissionDetailsWrapper
                                            mainDivKey={mainDivKey.toString()}
                                            onPostLoginComplete={onPostLoginComplete}
                                        />}
                                />
                            </Route>
                            <Route path={routeConstants.workspaceSubmissionDetailsFinancialStatements}>
                                <Route
                                    path={`${routeConstants.workspaceIdParam}/${routeConstants.submissionIdParam}`}
                                    element={
                                        <SubmissionDetailsWrapper
                                            mainDivKey={mainDivKey.toString()}
                                            onPostLoginComplete={onPostLoginComplete}
                                            hideFooter={true}
                                        />
                                    }
                                />
                            </Route>
                            <Route path={routeConstants.workspaceSubmissionDetailsRiskAnalysis}>
                                <Route
                                    path={`${routeConstants.workspaceIdParam}/${routeConstants.submissionIdParam}`}
                                    element={
                                        <SubmissionDetailsWrapper
                                            mainDivKey={mainDivKey.toString()}
                                            onPostLoginComplete={onPostLoginComplete}
                                        />
                                    }
                                />
                            </Route>
                        </Route>
                        <Route path={routeConstants.allSubmissionDetails}>
                            <Route path={routeConstants.allSubmissionDetailsOverview}>
                                <Route
                                    path={`${routeConstants.workspaceIdParam}/${routeConstants.submissionIdParam}`}
                                    element={
                                        <SubmissionDetailsWrapper
                                            mainDivKey={mainDivKey.toString()}
                                            onPostLoginComplete={onPostLoginComplete}
                                        />
                                    }
                                />
                            </Route>
                            <Route path={routeConstants.allSubmissionDetailsFinancialStatements}>
                                <Route
                                    path={`${routeConstants.workspaceIdParam}/${routeConstants.submissionIdParam}`}
                                    element={
                                        <SubmissionDetailsWrapper
                                            mainDivKey={mainDivKey.toString()}
                                            onPostLoginComplete={onPostLoginComplete}
                                        />
                                    }
                                />
                            </Route>
                            <Route path={routeConstants.allSubmissionDetailsRiskAnalysis}>
                                <Route
                                    path={`${routeConstants.workspaceIdParam}/${routeConstants.submissionIdParam}`}
                                    element={
                                        <SubmissionDetailsWrapper
                                            mainDivKey={mainDivKey.toString()}
                                            onPostLoginComplete={onPostLoginComplete}
                                        />
                                    }
                                />
                            </Route>
                        </Route>
                        <Route path={routeConstants.workspaceDetails}>
                            <Route path={routeConstants.workspaceDetailsSubmissions}>
                                <Route
                                    path={`${routeConstants.workspaceIdParam}`}
                                    element={<WorkspaceDetailsWrapper
                                        mainDivKey={mainDivKey.toString()}
                                        onPostLoginComplete={onPostLoginComplete}
                                    />}
                                />
                            </Route>
                            <Route path={routeConstants.workspaceDetailsConnections}>
                                <Route
                                    path={`${routeConstants.workspaceIdParam}`}
                                    element={<WorkspaceDetailsWrapper
                                        mainDivKey={mainDivKey.toString()}
                                        onPostLoginComplete={onPostLoginComplete}
                                    />}
                                />
                            </Route>
                            <Route path={routeConstants.workspaceDetailsUsers}>
                                <Route
                                    path={`${routeConstants.workspaceIdParam}`}
                                    element={<WorkspaceDetailsWrapper
                                        mainDivKey={mainDivKey.toString()}
                                        onPostLoginComplete={onPostLoginComplete}
                                    />}
                                />
                            </Route>
                            <Route path={routeConstants.workspaceDetailsSubmissionList}>
                                <Route
                                    path={`${routeConstants.workspaceIdParam}`}
                                    element={<WorkspaceDetailsWrapper
                                        mainDivKey={mainDivKey.toString()}
                                        onPostLoginComplete={onPostLoginComplete}
                                    />}
                                />
                            </Route>
                        </Route>
                        <Route
                            path={routeConstants.login}
                            element={
                                <LenderPortal
                                    hideMenu={true}
                                    hideLoggedInUser={true}
                                    disableUIRefreshTiming={true}
                                >
                                    <Login />
                                </LenderPortal>
                            }
                        />
                        <Route
                            path={routeConstants.dataCollectionReport}
                            element={
                                <AuthorizedWindowWrapper
                                    securedOp={'CanViewGlobalSubmissions'}
                                    mainDivKey={mainDivKey.toString()}
                                    onPostLoginComplete={onPostLoginComplete}
                                >
                                    <LenderPortal>
                                        <DataCollectionReport />
                                    </LenderPortal>
                                </AuthorizedWindowWrapper>
                            }
                        />
                        <Route
                            path={routeConstants.authTimeout}
                            element={
                                <LenderPortal
                                    hideMenu={true}
                                    disableUIRefreshTiming={true}
                                >
                                    <AuthTimedOut />
                                </LenderPortal>
                            }
                        />
                        <Route
                            path={routeConstants.slidingWindowTimeout}
                            element={
                                <LenderPortal
                                    hideMenu={true}
                                    disableUIRefreshTiming={true}
                                >
                                    <SlidingTimeout />
                                </LenderPortal>
                            }
                        />
                        <Route
                            path={routeConstants.notFound}
                            element={
                                <PageNotFound />
                            }
                        />
                        <Route
                            path={routeConstants.tenantLenderDisabled}
                            element={
                                <BasicStrongboxContentContainer>
                                    <TenantDisabled
                                        flow={'lender'}
                                    />
                                </BasicStrongboxContentContainer>
                            }
                        />
                        <Route
                            path={routeConstants.tenantBorrowerDisabled}
                            element={
                                <BasicStrongboxContentContainer>
                                    <TenantDisabled
                                        flow={'borrower'}
                                    />
                                </BasicStrongboxContentContainer>
                            }
                        />
                        <Route path={routeConstants.settings}>
                            <Route path={''} element={<Navigate to={pathConstants.settingsDefault} />} />
                            <Route
                                path={routeConstants.settingsUserManagement}
                                element={
                                    <AuthorizedWindowWrapper
                                        securedOp={'CanManageUsers'}
                                        mainDivKey={mainDivKey.toString()}
                                        onPostLoginComplete={onPostLoginComplete}
                                    >
                                        <LenderPortal>
                                            <SettingsContainer />
                                        </LenderPortal>
                                    </AuthorizedWindowWrapper>
                                }
                            />
                            <Route
                                path={routeConstants.settingsNotifications}
                                element={
                                    <LenderPortal>
                                        <UserSettingsContainer
                                            subPage={UserSettingsSubPage.notifications}
                                        />
                                    </LenderPortal>
                                }
                            />
                            <Route
                                path={routeConstants.settingsAdminNotifications}
                                element={
                                    <LenderPortal>
                                        <UserSettingsContainer
                                            subPage={UserSettingsSubPage.admin}
                                        />
                                    </LenderPortal>
                                }
                            />
                        </Route>

                        <Route
                            path={routeConstants.tenant}
                            element={
                                <AuthorizedWindowWrapper
                                    mainDivKey={mainDivKey.toString()}
                                    onPostLoginComplete={onPostLoginComplete}
                                >
                                    <LenderPortal>
                                        <TenantSelection />
                                    </LenderPortal>
                                </AuthorizedWindowWrapper>
                            }
                        />
                        <Route
                            path={routeConstants.brand}
                            element={
                                <AuthorizedWindowWrapper
                                    mainDivKey={mainDivKey.toString()}
                                    onPostLoginComplete={onPostLoginComplete}
                                >
                                    <LenderPortal>
                                        <TenantSelection />
                                    </LenderPortal>
                                </AuthorizedWindowWrapper>
                            }
                        />
                        <Route
                            path={pathConstants.portal}
                            element={
                                <BorrowerPortal
                                    showTOU={true}
                                >
                                    {(props): JSX.Element => {
                                        return (
                                            <PortalSubmission
                                                backgroundImage={props.backgroundImage}
                                                borrowerId={props.borrowerId}
                                                setIsWorking={props.setIsWorking}
                                                setOverrideWorking={props.setOverrideWorking}
                                                strongboxModalThemes={props.strongboxModalThemes}
                                                strongboxUrl={props.strongboxUrl}
                                                submissionId={props.submissionId}
                                                welcome={props.welcome}
                                                working={props.working}
                                                scrollTop={props.scrollTop}
                                                importAccountingPkgAnonymous={props.importAccountingPkgAnonymous}
                                                importAccountingPkg={props.importAccountingPkg}
                                                submitWorkspaceInformationAndRedirectToDocs={props.submitWorkspaceInformationAndRedirectToDocs}
                                                createSubmissionAndRedirectDocs={props.createSubmissionAndRedirectDocs}
                                            />
                                        )
                                    }}
                                </BorrowerPortal>
                            }
                        />
                        <Route
                            path={routeConstants.submission}
                            element={
                                <BorrowerPortal
                                    showTOU={true}
                                >
                                    {(props): JSX.Element => {
                                        return (
                                            <PortalSubmission
                                                backgroundImage={props.backgroundImage}
                                                borrowerId={props.borrowerId}
                                                setIsWorking={props.setIsWorking}
                                                setOverrideWorking={props.setOverrideWorking}
                                                strongboxModalThemes={props.strongboxModalThemes}
                                                strongboxUrl={props.strongboxUrl}
                                                submissionId={props.submissionId}
                                                welcome={props.welcome}
                                                working={props.working}
                                                scrollTop={props.scrollTop}
                                                importAccountingPkgAnonymous={props.importAccountingPkgAnonymous}
                                                importAccountingPkg={props.importAccountingPkg}
                                                submitWorkspaceInformationAndRedirectToDocs={props.submitWorkspaceInformationAndRedirectToDocs}
                                                createSubmissionAndRedirectDocs={props.createSubmissionAndRedirectDocs}
                                            />
                                        )
                                    }}
                                </BorrowerPortal>
                            }
                        />
                        <Route
                            path={routeConstants.submissionSansFileUpload}
                            element={
                                <BorrowerPortal
                                    showTOU={true}
                                >
                                    {(props): JSX.Element => {
                                        return (
                                            <PortalSubmission
                                                backgroundImage={props.backgroundImage}
                                                borrowerId={props.borrowerId}
                                                setIsWorking={props.setIsWorking}
                                                setOverrideWorking={props.setOverrideWorking}
                                                strongboxModalThemes={props.strongboxModalThemes}
                                                strongboxUrl={props.strongboxUrl}
                                                submissionId={props.submissionId}
                                                welcome={props.welcome}
                                                working={props.working}
                                                scrollTop={props.scrollTop}
                                                importAccountingPkgAnonymous={props.importAccountingPkgAnonymous}
                                                importAccountingPkg={props.importAccountingPkg}
                                                submitWorkspaceInformationAndRedirectToDocs={props.submitWorkspaceInformationAndRedirectToDocs}
                                                createSubmissionAndRedirectDocs={props.createSubmissionAndRedirectDocs}
                                            />
                                        )
                                    }}
                                </BorrowerPortal>
                            }
                        />
                        <Route
                            path={routeConstants.progress}
                            element={
                                <BorrowerPortal>
                                    {(props): JSX.Element => {
                                        return (
                                            <Progress
                                                backgroundImage={props.backgroundImage}
                                                borrowerId={props.borrowerId}
                                                setIsWorking={props.setIsWorking}
                                                setOverrideWorking={props.setOverrideWorking}
                                                strongboxModalThemes={props.strongboxModalThemes}
                                                strongboxUrl={props.strongboxUrl}
                                                submissionId={props.submissionId}
                                                welcome={props.welcome}
                                                working={props.working}
                                                scrollTop={props.scrollTop}
                                                importAccountingPkgAnonymous={props.importAccountingPkgAnonymous}
                                                importAccountingPkg={props.importAccountingPkg}
                                                submitWorkspaceInformationAndRedirectToDocs={props.submitWorkspaceInformationAndRedirectToDocs}
                                                createSubmissionAndRedirectDocs={props.createSubmissionAndRedirectDocs}
                                            />
                                        )
                                    }}
                                </BorrowerPortal>
                            }
                        />
                        <Route
                            path={routeConstants.linked}
                            element={
                                <BorrowerPortal>
                                    {(props): JSX.Element => {
                                        return (
                                            <Linked
                                                backgroundImage={props.backgroundImage}
                                                borrowerId={props.borrowerId}
                                                setIsWorking={props.setIsWorking}
                                                setOverrideWorking={props.setOverrideWorking}
                                                strongboxModalThemes={props.strongboxModalThemes}
                                                strongboxUrl={props.strongboxUrl}
                                                submissionId={props.submissionId}
                                                welcome={props.welcome}
                                                working={props.working}
                                                scrollTop={props.scrollTop}
                                                importAccountingPkgAnonymous={props.importAccountingPkgAnonymous}
                                                importAccountingPkg={props.importAccountingPkg}
                                                submitWorkspaceInformationAndRedirectToDocs={props.submitWorkspaceInformationAndRedirectToDocs}
                                                createSubmissionAndRedirectDocs={props.createSubmissionAndRedirectDocs}
                                            />
                                        )
                                    }}
                                </BorrowerPortal>
                            }
                        />
                        <Route
                            path={routeConstants.documents}
                            element={
                                <BorrowerPortal>
                                    {(props): JSX.Element => {
                                        return (
                                            <Documents
                                                backgroundImage={props.backgroundImage}
                                                borrowerId={props.borrowerId}
                                                setIsWorking={props.setIsWorking}
                                                setOverrideWorking={props.setOverrideWorking}
                                                strongboxModalThemes={props.strongboxModalThemes}
                                                strongboxUrl={props.strongboxUrl}
                                                submissionId={props.submissionId}
                                                welcome={props.welcome}
                                                working={props.working}
                                                scrollTop={props.scrollTop}
                                                importAccountingPkgAnonymous={props.importAccountingPkgAnonymous}
                                                importAccountingPkg={props.importAccountingPkg}
                                                submitWorkspaceInformationAndRedirectToDocs={props.submitWorkspaceInformationAndRedirectToDocs}
                                                createSubmissionAndRedirectDocs={props.createSubmissionAndRedirectDocs}
                                            />
                                        )
                                    }}
                                </BorrowerPortal>
                            }
                        />
                        <Route
                            path={routeConstants.shareableLinkExpired}
                            element={
                                <BorrowerPortal
                                    showTOU={false}
                                >
                                    {(props): JSX.Element => {
                                        return (
                                            <ShareableLinkExpired
                                                backgroundImage={props.backgroundImage}
                                                borrowerId={props.borrowerId}
                                                setIsWorking={props.setIsWorking}
                                                setOverrideWorking={props.setOverrideWorking}
                                                strongboxModalThemes={props.strongboxModalThemes}
                                                strongboxUrl={props.strongboxUrl}
                                                submissionId={props.submissionId}
                                                welcome={props.welcome}
                                                working={props.working}
                                                scrollTop={props.scrollTop}
                                                importAccountingPkgAnonymous={props.importAccountingPkgAnonymous}
                                                importAccountingPkg={props.importAccountingPkg}
                                                submitWorkspaceInformationAndRedirectToDocs={props.submitWorkspaceInformationAndRedirectToDocs}
                                                createSubmissionAndRedirectDocs={props.createSubmissionAndRedirectDocs}
                                            />
                                        )
                                    }}
                                </BorrowerPortal>
                            }
                        />
                        <Route
                            path={routeConstants.shareableLinkDeleted}
                            element={
                                <BorrowerPortal
                                    showTOU={false}
                                >
                                    {(props): JSX.Element => {
                                        return (
                                            <ShareableLinkDeleted
                                                backgroundImage={props.backgroundImage}
                                                borrowerId={props.borrowerId}
                                                setIsWorking={props.setIsWorking}
                                                setOverrideWorking={props.setOverrideWorking}
                                                strongboxModalThemes={props.strongboxModalThemes}
                                                strongboxUrl={props.strongboxUrl}
                                                submissionId={props.submissionId}
                                                welcome={props.welcome}
                                                working={props.working}
                                                scrollTop={props.scrollTop}
                                                importAccountingPkgAnonymous={props.importAccountingPkgAnonymous}
                                                importAccountingPkg={props.importAccountingPkg}
                                                submitWorkspaceInformationAndRedirectToDocs={props.submitWorkspaceInformationAndRedirectToDocs}
                                                createSubmissionAndRedirectDocs={props.createSubmissionAndRedirectDocs}
                                            />
                                        )
                                    }}
                                </BorrowerPortal>
                            }
                        />
                        <Route
                            path={routeConstants.confirm}
                            element={
                                <EmailConfirm />
                            }
                        />
                        <Route
                            path={routeConstants.confirming}
                            element={
                                <EmailConfirm delayConfirm={true} />
                            }
                        />
                        <Route
                            path={routeConstants.downloadUserCopy}
                            element={
                                <BorrowerPortal>
                                    {(props): JSX.Element => {
                                        return (
                                            <DownloadUserCopy
                                                backgroundImage={props.backgroundImage}
                                                borrowerId={props.borrowerId}
                                                setIsWorking={props.setIsWorking}
                                                setOverrideWorking={props.setOverrideWorking}
                                                strongboxModalThemes={props.strongboxModalThemes}
                                                strongboxUrl={props.strongboxUrl}
                                                submissionId={props.submissionId}
                                                welcome={props.welcome}
                                                working={props.working}
                                                importAccountingPkgAnonymous={props.importAccountingPkgAnonymous}
                                                importAccountingPkg={props.importAccountingPkg}
                                                submitWorkspaceInformationAndRedirectToDocs={props.submitWorkspaceInformationAndRedirectToDocs}
                                                createSubmissionAndRedirectDocs={props.createSubmissionAndRedirectDocs}
                                            />
                                        )
                                    }}
                                </BorrowerPortal>
                            }
                        />
                    </Routes>
                </Wrapper>
            </BrowserRouter>
        </LoggingWrapper>
    );
}

export default connect<ReduxStateProps, ReduxDispatchProps, PropArguments, ApplicationState>(
    // map state to props
    (state: ApplicationState) => {
        return {
            appSettings: state.appSettings,
            tenantSettings: GetBasicSettings(state),
            importHasStarted: ImportHasStarted(state),
            tenantName: GetTenantName(state),
        };
    },
    // map dispatch to props
    dispatch => bindActionCreators(
        {
            ...AppSettings.actionCreators,
            ...UserActions,
            ...BorrowerActionCreators,
            ...TenantActionCreators,
        },
        dispatch
    )
)(App);
