import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';

import { useAuth0 } from "@auth0/auth0-react";

import { Theme, ThemeProvider } from '@mui/material/styles';
import { CreateStdTheme } from '../Utils/Style';

import { PortalHeader } from './PortalHeader';
import { PortalFooter } from './PortalFooter';

import { Ping } from '../Services/AuthService';

import { ApplicationState } from '../Store';
import { actionCreators as AppSettingsActions } from '../Store/AppSettings';
import {
    actionCreators as ImportFinancialsActions,
    CustomerFinancialsTarget,
    DirectLinkParameters,
    GetImportCustomerFinancialsTarget,
    GetImportingDirect,
    GetImportMap,
    ImportMap,
} from '../Store/ImportFinancials';
import { actionCreators as UserActions } from '../Store/User'
import { BrandConfig, GetBrandConfig } from '../Store/Tenant';
import { GetLoginSessionSlidingLifetimeInSeconds } from '../Store/AppSettings';
import { 
    actionCreators as UIStateActions, 
    GetPortalBusyState, 
    MillisecondCountLastUserInteraction,
    GetRefreshStateTicker,
} from '../Store/UIState';

import { 
    pathConstants,
    retryConnectForImport,
    UpdateUIStateTimeout,
    auls_info_cookie_name,
} from '../Utils/Constants';

import { AccountingPackage } from '../Models/Api/AccountingPackages';
import { CookieSessionData } from '../Models/CookieSessionData';

import { FinancialsImport } from './FinancialsImport/FinancialsImport';
import { ErrorBanner, ErrorState } from './ErrorBanner/ErrorBanner';

import './Main.scss';

type InjectedReduxState = {
    portalBusy: boolean;
    financialsTarget: CustomerFinancialsTarget | undefined;
    directLinkParameters: DirectLinkParameters | undefined;
    importMap: ImportMap[];
    brandConfig: BrandConfig;
    lastUserInteractionMS: number;
    sessionSlidingLifetimeSeconds: number;
    uiRefreshTicker: number;
};

type InjectedActionCreators = typeof AppSettingsActions & typeof UserActions & typeof UIStateActions & typeof ImportFinancialsActions;

type LenderPortalProps = {
    hideMenu?: boolean;
    hideLoggedInUser?: boolean;
    children?: any;
    hideFooter?: boolean;
    disableUIRefreshTiming?: boolean;
}

type Props = LenderPortalProps & InjectedActionCreators & InjectedReduxState;

const LenderPortalComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        directLinkParameters,
        financialsTarget,
        importMap,
        hideFooter,
        lastUserInteractionMS,
        UpdateLastUserInteraction,
        UpdateUIStateTimer,
        sessionSlidingLifetimeSeconds,
        uiRefreshTicker,
        disableUIRefreshTiming,
    } = props;

    const navigate = useNavigate();

    const [cookies] = useCookies([auls_info_cookie_name]);
    const { logout } = useAuth0();

    const [uiStateUpdate, setUiStateUpdate] = React.useState<boolean>(false);
    const [cookieSessionData, setSessionCookieData] = React.useState<CookieSessionData | undefined>(undefined);

    const [muiTheme] = React.useState<Theme>(() => {
        return CreateStdTheme(props.brandConfig);
    });

    const [errorState, setErrorState] = React.useState<ErrorState | undefined>(undefined);

    React.useEffect(() => {
        if (!disableUIRefreshTiming) {
            const timeNow = Date.now();

            let pauseUpdates = false;

            // Check the time the path (url in the browser) last changed. If it's been longer than
            // half a sliding session interval than pause execution of the timer.
            // Cookie update of the session expiration will not occur until we're past the 
            // halfway point of the a session interval so this will shut down authenticated 
            // api calls before it's reset and result in the cookie actually expiring.
            //
            // Don't worry about the absolute time that represents half of the current interval.
            // Merely having an update greater than half the interval is sufficient. Because
            // the cookie can be updated without making a network call, these aren't necessarily
            // the same thing.

            if ((!!cookieSessionData) && (!!cookieSessionData.slidingExpirationTime)) {
                const sessionSlidingLifetimeMS = sessionSlidingLifetimeSeconds * 1000;

                if (timeNow - lastUserInteractionMS > (sessionSlidingLifetimeMS / 2)) {
                    pauseUpdates = true;
                }
            }

            if ((uiStateUpdate) && (!!cookieSessionData)) {
                if (!pauseUpdates) {
                    UpdateUIStateTimer(cookieSessionData);
                }
                setUiStateUpdate(false);
            }
        }

        // These are the dependencies I actually want.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uiStateUpdate, cookieSessionData, sessionSlidingLifetimeSeconds, lastUserInteractionMS]);

    React.useEffect(() => {
        if (!disableUIRefreshTiming) {
            // Depending on what window is loaded we may or may not be making periodic api calls that will help keep the 
            // sliding session cookie alive. Low cost ping here to make sure it keeps going.
            Ping();
        }

        // These are the dependencies I actually want.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uiRefreshTicker]);

    // Lint will complain about the calls to setSessionCookieData however there are sufficient
    // guards to ensure this won't result in infinite looping as it checks the values of 
    // liveCookieSessionData to ensure they're different from what we have stored.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        if (!disableUIRefreshTiming) {
            const liveCookieSessionData = cookies[auls_info_cookie_name] as CookieSessionData;

            if (!cookieSessionData) {
                setSessionCookieData(liveCookieSessionData);
            } else {
                if ((liveCookieSessionData.absoluteExpirationTime !== cookieSessionData.absoluteExpirationTime) || 
                    (liveCookieSessionData.slidingExpirationTime !== cookieSessionData.slidingExpirationTime)) 
                {
                    setSessionCookieData(liveCookieSessionData);
                }
            }
        }
        // Actually do want this to run on every render 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    function ExecuteStateUpdateTimer() {
        setUiStateUpdate(true);
    }

    function TrackMouseDown(this: HTMLElement, ev: Event): void {
        UpdateLastUserInteraction();
    }

    React.useEffect(() => {
        if (!disableUIRefreshTiming) {
            window.addEventListener('mousedown', TrackMouseDown);
            const idTimer = setInterval(ExecuteStateUpdateTimer, UpdateUIStateTimeout);

            return () => { 
                clearInterval(idTimer); 
                window.removeEventListener('mousedown', TrackMouseDown);
            }
        }

        // I want this to execute equivalent to componentDidMount so this is appropriate
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setErrorStateOnImport = (errorState: ErrorState | undefined, accountingPackage: AccountingPackage, connectionId?: string): void => {
        if ((!errorState) || (!(props.financialsTarget && props.directLinkParameters))) {
            setErrorState(undefined);
        } else {
            setErrorState({
                ...errorState,
                actions: [
                    {
                        text: 'Dismiss',
                        id: 'skiprelink',
                        onAction: (id: string) => {
                            setErrorState(undefined);
                        }
                    },
                    {
                        text: retryConnectForImport,
                        id: 'startrelink',
                        onAction: (id: string) => {
                            const map = props.importMap.find(
                                mapEntry => {
                                    if (connectionId !== undefined) {
                                        return connectionId === mapEntry.importRequest.connectionId;
                                    } else {
                                        return (mapEntry.workspaceId === props.financialsTarget!.workspaceId && mapEntry.accountingPackage.toLowerCase() === accountingPackage.toLowerCase());
                                    }
                                }
                            );
                            if (!!map) {
                                props.RetryImportRequest(map);
                            }
                            setErrorState(undefined);
                        }
                    }
                ]
            });
        }
    }

    const controlClass = `control-region control-region-lender`;
    const mainDivClass =
        `free-content-region ${controlClass}`;

    const errorActions = React.useMemo(() => {
        if (!(financialsTarget && directLinkParameters)) {
            return undefined;
        } else {
            return (
                [
                    {
                        text: 'Dismiss',
                        id: 'dismissaction',
                        onAction: (actionId: string) => {
                            setErrorState(undefined);
                        }
                    },
                    {
                        text: retryConnectForImport,
                        id: 'entercredsandcontinue',
                        onAction: (id: string) => {
                            let keyAccountingPkg = directLinkParameters?.accountingPkg;
                            let connectionIdKey = directLinkParameters?.connectionId;

                            if (!!(keyAccountingPkg || connectionIdKey)) {
                                const map = importMap.find(
                                    mapEntry => {
                                        if (connectionIdKey !== undefined) {
                                            return mapEntry.importRequest.connectionId === connectionIdKey;
                                        } else {
                                            return mapEntry.workspaceId === financialsTarget!.workspaceId &&
                                                mapEntry.accountingPackage.toLowerCase() === keyAccountingPkg?.toLowerCase();
                                        }
                                    }
                                );
                                if (!!map) {
                                    props.RetryImportRequest(map);
                                }
                            }
                            setErrorState(undefined);
                        }
                    }
                ]                
            )
        }
        // These are the state that actually affect the execution.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [financialsTarget, directLinkParameters, importMap]);

    return (
        <div>
            <ThemeProvider theme={muiTheme}>
                <PortalHeader
                    hideMenu={props.hideMenu}
                    logout={() => {
                        props.LogUserOut(logout);
                    }}
                    settings={() => {
                        navigate(pathConstants.settingsDefault);
                    }}
                    dataCollectionReport={() => {
                        navigate(pathConstants.dataCollectionReport);
                    }}
                    notificationSettings={() => {
                        navigate(pathConstants.settingsNotifications);
                    }}
                    showLoggedInUser={props.hideLoggedInUser !== true}
                />
                {
                    !!errorState && (
                        <div style={{ width: '100%' }} className={mainDivClass}>
                            <div style={{ height: '100%' }} className={'contained-content'}>
                                <ErrorBanner
                                    errorState={{...errorState, actions: errorActions}}
                                    onDefaultActionButton={() => {
                                        setErrorState(undefined);
                                    }}
                                />
                            </div>
                        </div>
                    )
                }
                {
                    props.children
                }
                {
                    hideFooter !== true && (<PortalFooter />)
                }
                <FinancialsImport
                    onSetErrorState={(errorState) => {
                        if (!!props.directLinkParameters) {
                            setErrorStateOnImport(
                                errorState,
                                props.directLinkParameters.accountingPkg,
                                props.directLinkParameters.connectionId
                            );
                        } else {
                            setErrorState(errorState);
                        }
                    }}
                    onSetImporting={(working) => working ? props.SetPortalWorking('FinancialsImportProperty') : props.SetPortalIdle('FinancialsImportProperty')  }
                />
            </ThemeProvider>
        </div>
    );
}

export const LenderPortal = connect<InjectedReduxState, InjectedActionCreators, LenderPortalProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result = {
            portalBusy: GetPortalBusyState(appState),
            financialsTarget: GetImportCustomerFinancialsTarget(appState),
            directLinkParameters: GetImportingDirect(appState),
            importMap: GetImportMap(appState),
            brandConfig: GetBrandConfig(appState),
            sessionSlidingLifetimeSeconds: GetLoginSessionSlidingLifetimeInSeconds(appState),
            lastUserInteractionMS: MillisecondCountLastUserInteraction(appState),
            uiRefreshTicker: GetRefreshStateTicker(appState),
        };

        return result;
    },
    {
        ...AppSettingsActions,
        ...UserActions,
        ...UIStateActions,
        ...ImportFinancialsActions
    }
)(LenderPortalComponent);
