import * as React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ApplicationState } from '../../Store';
import { actionCreators as UIStateActions, GetRefreshStateTicker, GetPortalBusyState } from '../../Store/UIState';
import { GetBannerMessages, GetTenantName, TenantActionCreators } from '../../Store/Tenant';
import { actionCreators as RunningImportsActions } from '../../Store/RunningImports';
import { GetSubmissionsPendingLenderIntervention, actionCreators as SubmissionActions } from '../../Store/Submission';
import { WorkspaceList } from './WorkspaceList/WorkspaceList';
import { MessageBanner } from '../BannerMessage/BannerMessage';
import { SubmissionsPendingLenderIntervention } from '../SubmissionsPendingLenderIntervention/SubmissionsPendingLenderIntervention';

import { BusyWindowWrapper } from '../LoadingMessageWithIndicator/BusyWindowWrapper';

import { ConfigurableMessageBanner } from '../ConfigurableMessageBanner/ConfigurableMessageBanner';
import { BannerMessage, SubmissionSearchResult } from '../../Models/Api/strongbox.financialportal';

type TabNames = 'workspacelist' | 'submissionlist';

type InjectedReduxState = {
    activeTab?: TabNames;
    portalBusy: boolean;
    bannerMessages: BannerMessage[];
    tenantName: string;
    submissionsPendingLenderIntervention: SubmissionSearchResult[];
    refreshStateTicker: number;
};

type InjectedActionCreators = typeof RunningImportsActions & typeof TenantActionCreators & typeof SubmissionActions & typeof UIStateActions;

type LenderHomeProps = {};

type Props = LenderHomeProps & InjectedActionCreators & InjectedReduxState;

export const LenderHomeComponent: React.FC<Props> = (props): React.ReactElement => {
    const {
        bannerMessages,
        FetchBannerMessages,
        ListPendingJobs,
        LoadSubmissionsPendingLenderIntervention,
        SetPortalWorking,
        SetPortalIdle,
        submissionsPendingLenderIntervention,
        SubmissionPendingInterventionDeleted,
        portalBusy, 
        refreshStateTicker,
    } = props;

    React.useEffect(() => {
        ListPendingJobs(false);
        LoadSubmissionsPendingLenderIntervention();
        
        // These are the dependencies I actually want.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshStateTicker])

    React.useEffect(() => {
        FetchBannerMessages();

        const workingState = 'LenderHomeFetchingSubmissionPendingIntervention';
        SetPortalWorking(workingState);
        LoadSubmissionsPendingLenderIntervention(
            () => {
                SetPortalIdle(workingState);
            }
        );

        // I want this to run as componentDidMount so no dependencies in the list
        // eslint-disable-next-line react-hooks/exhaustive-deps                                                                                                         
    }, [])

    return (
        <div 
            style={{ position: 'absolute', width: '100%' }} 
            className={`free-content-region`} 
        >
            {
                bannerMessages.map((msg, msgIndex) => (
                    <ConfigurableMessageBanner
                        message={msg}
                        key={`configurable-message-container-outer-${msgIndex}`}
                        keyIndex={msgIndex}
                    />
                ))
            }
            {
                submissionsPendingLenderIntervention.length > 0 && (
                    <MessageBanner>
                        <SubmissionsPendingLenderIntervention 
                            submissions={submissionsPendingLenderIntervention}
                            onSubmissionDeleted={(sub) => SubmissionPendingInterventionDeleted(
                                sub.entity.id,
                                sub.submission.id
                            )}
                        />
                    </MessageBanner>
                )
            }
            <div style={{ height: '100%' }} className={`contained-content control-region control-region-lender`} >
                <BusyWindowWrapper
                    portalBusy={portalBusy}
                >
                    <WorkspaceList 
                        onWorkspaceUsersChanged={() => {
                            LoadSubmissionsPendingLenderIntervention();                            
                        }}
                    />
                </BusyWindowWrapper>
            </div>
        </div>
    );
}

export const LenderHome = connect<InjectedReduxState, InjectedActionCreators, LenderHomeProps, ApplicationState>(
    (appState: ApplicationState) => {
        const result: InjectedReduxState = {
            portalBusy: GetPortalBusyState(appState),
            bannerMessages: GetBannerMessages(appState),
            tenantName: GetTenantName(appState),
            submissionsPendingLenderIntervention: GetSubmissionsPendingLenderIntervention(appState),
            refreshStateTicker: GetRefreshStateTicker(appState),
        };

        return result;
    },
    dispatch => bindActionCreators(
        {
            ...RunningImportsActions,
            ...TenantActionCreators,
            ...SubmissionActions,
            ...UIStateActions,
        },
        dispatch
    )
)(LenderHomeComponent);
