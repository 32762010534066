import { BrandConfig } from './Reducer';
import { BannerMessage, TenantUserMetadataSetting } from '../../Models/Api/strongbox.financialportal';

export enum TenantActions {
    TenantSetResource = 'Tenant/SET_RESOURCE',
    TenantSetBasicSettings = 'Tenant/SET_BASIC_SETTINGS',
    TenantSetBrandConfig = 'Tenant/SET_BRAND_CONFIG',
    TenantOverrideActiveTenant = 'Tenant/SET_ACTIVE_TENANT',
    TenantListEmailDomains = 'Tenant/LIST_EMAIL_DOMAINS',
    TenantListEmailDomainsComplete = 'Tenant/LIST_EMAIL_DOMAINS_COMPLETE',
    TenantListUserMetadataSettings = 'Tenant/LIST_USER_METADATA_SETTINGS',
    TenantListUserMetadataSettingsComplete = 'Tenant/LIST_USER_METADATA_SETTINGS_COMPLETE',
    TenantGetBannerMessages = 'Tenant/GET_BANNER_MESSAGES',
    TenantGetBannerMessagesComplete = 'Tenant/GET_BANNER_MESSAGES_COMPLETE',
    TenantSetWorkingTenantName = 'Tenant/SET_WORKING_TENANT_NAME',
}

export enum ResourceName {
    basicTenantSettings = 'basic-tenant-settings',
    connectTenantDocsUploadPrompt = 'connect-tenant-doc-upload-text',
    connectTenantUploadLink = 'connect-tenant-upload-link-text',
    connectSelectAccountingPkg = 'connect-select-accounting-pkg',
    connectEnterBusinessInfo = 'connect-enter-business-info',
    stylePalette = 'style-palette',
    tenantAuthorizationTimedOut = 'tenant-auth-timeout',
    tenantSlidingWindowTimedOut = 'tenant-auth-sliding-window-timeout',
    tenantWelcomeText = 'tenant-welcome-text',
    tenantReportList = 'tenant-report-list',
    tenantConfirmationText = 'tenant-email-confirmation',
    tenantConfirmingText = 'tenant-email-confirming',
    tenantProgressSteps = 'tenant-progress-steps',
    tenantDownloadUserCopy = 'tenant-download-user-copy',
    tenantDownloadNotExisting = 'tenant-download-not-existing',
    tenantLinkComplete = 'tenant-link-complete',
    tenantLinkCompleteImportOnly = 'tenant-link-complete-import-only',
    tenantLinkCompleteNoActions = 'tenant-link-complete-no-actions',
    tenantLinkReadyToUploadMoreDocs = 'tenant-link-ready-to-upload-more-docs',
    tenantLinkSubmitMoreDocs = 'tenant-link-submit-more-docs',
    tenantCollectFinancialsWarning = 'tenant-collect-financials-warning',
    tenantSelectAccountingSystemSupplemental = 'tenant-select-accounting-system-supplemental',
    tenantBorrowerIntroBannerText = 'tenant-borrower-intro-banner',
    tenantBorrowerParametersBullets = 'tenant-borrower-parameters-bullets',
    tenantShareableLinkExpiredParagraphs = 'tenant-borrower-shareable-link-expired',
    tenantShareableLinkDeletedParagraphs = 'tenant-borrower-shareable-link-deleted',
    tenantDataCollectionGroupDescriptions = 'tenant-data-collection-group-descriptions',
    tenantDataCollectionGroupDescriptionsExcel = 'tenant-data-collection-group-descriptions-excel',
    tenantAdditionalMessagingSelectAccountingDataPage = 'tenant-additional-messaging-acctg-data-page',
};

// Just a couple customer names are listed here under KnownTenants.  There are, of course,
// a whole bunch more.  These are specifically referenced in brandutils.tsx for text
// overrides and thus warrant a constant instead of just magic strings.

export enum KnownTenants {
    defaultLender = 'defaultLender',
    defaultAccountant = 'defaultAccountant',
    strongbox = 'strongbox',
    chase = 'chase',
    chasesandbox = 'chase-sandbox',
    wipfli = 'wipfli',
    mlrpc = 'mlrpc',
    cohencpa = 'cohencpa',
    bmss = 'bmss',
    rsm = 'rsm',
};

export type TenantPaletteItem = {
    name: string,
    page: string,
    value: any,
};

export type TenantPalette = {
    paletteColletion: Record<string, TenantPaletteItem>,
};

export enum PaletteItemNames {
    h1Login = 'h1-login',
}

export type TenantParagraphSet = {
    paragraphs: string[];
}

export type TenantParagraphAttribute = {
    name: string;
    value: string;
}

export type TenantAttributedParagraphSet = {
    paragraphs: {
        attributes: TenantParagraphAttribute[],
        content: string;
    }[]
}

export type TenantLineItemWithImage = {
    img: (className?: string) => React.ReactElement;
    text: string;
}

export type TenantLineItemImageSet = {
    lineItems: TenantLineItemWithImage[];
}

export type TenantLoginDestination = {
    id: string;
    name: string;
    url: string;
    tenantName: string;
    // if buttonText is present, this text will be used on the login button for
    // the tenant, otherwise just the name will be used.
    buttonText?: string;
}

export type TenantLoginText = {
    bold?: boolean;
    fontSize?: number; // value in pix
    bottomMargin?: number;  // value in pix
    // Straight up content for a paragraph of text.  There is one special delimiter.
    // If content contains one or more instances of '<team>' that delimiter will 
    // be replaced with the name of the team associated with the portal to which they
    // are signing in.
    content: string;
}

export type MultiTenantSettings = {
    // If defaultLogin is undefined or true, a login button will be shown on
    // the main screen and no selection button will be presented for the team portal
    // to which the user is signing in.  Presumably, some welcome text (see how that's defined)
    // will contain the name of the team to which they're signing in.
    defaultLogin?: boolean;

    welcomeText?: TenantLoginText[];

    // If set, placeLoginAfterWelcomeLine will place the login button after this line
    // from the welcome text.  If this is 0, the button is at the top.   If 
    // defaultLogin is false, this has no effect.
    placeLoginAfterWelcomeLine?: number;

    showSeparatorAfterWelcomeText?: boolean;
    passLoginThrough?: boolean;
    loginDestinations: TenantLoginDestination[];
}

export type TenantFlowDisabledContent = {
    paragraphs: TenantLoginText[];
}

export type TenantDisable = {
    lender?: TenantFlowDisabledContent;
    borrower?: TenantFlowDisabledContent;
}

/**
 * @member { Record<string, string> } redirections - allows for redirecting specific pages under a tenant. 
 * The first value in the record, the key is the pathname, e.g. '/portal' and the second value is the
 * target, e.g. 'notfound'.  In this example portal is redirected to notfound.
 * */

export type TenantBasicSettings = {
    background?: string;
    connectWelcomeMessage: string;
    defaultResource: KnownTenants.defaultLender | KnownTenants.defaultAccountant;
    favIconTitle: string;
    showStrongboxLogo: boolean;
    visibleName: string;
    redirections?: Record<string, string>;
    TOULink?: string;
    StrongboxSecurityInfoLink?: string;
    multiTenantSettings?: MultiTenantSettings;
    tenantDisable?: TenantDisable;
}

export type TenantResource = {
    tenantName: string;
    resource: string | TenantPalette | TenantParagraphSet | NodeRequire | TenantLineItemImageSet | TenantAttributedParagraphSet;
}

export type TenantResourceList = {
    resourceName: ResourceName;
    resources: TenantResource[];
}

export interface ISetResource {
    readonly type: TenantActions.TenantSetResource;
    readonly list: TenantResourceList;
}

export interface IOverrideActiveTenant {
    readonly type: TenantActions.TenantOverrideActiveTenant;
    readonly tenant: string;
}

export interface ISetBasicTenantSettings {
    readonly type: TenantActions.TenantSetBasicSettings;
    readonly settings: TenantBasicSettings;
}

export interface ISetBrandConfig {
    readonly type: TenantActions.TenantSetBrandConfig;
    readonly brandconfig: BrandConfig;
}

export interface IListEmailDomains {
    type: TenantActions.TenantListEmailDomains;
}

export interface IListEmailDomainsComplete {
    type: TenantActions.TenantListEmailDomainsComplete;
    domains: string[];
}

export interface ITenantListUserMetadataSettings {
    type: TenantActions.TenantListUserMetadataSettings;
}

export interface ITenantListUserMetadataSettingsComplete {
    type: TenantActions.TenantListUserMetadataSettingsComplete;
    metadataSettings: TenantUserMetadataSetting[];
}

export interface IGetBannerMessage {
    type: TenantActions.TenantGetBannerMessages;
}

export interface IGetBannerMessageComplete {
    type: TenantActions.TenantGetBannerMessagesComplete;
    messages: BannerMessage[];
}

export interface ISetWorkingTenantName {
    type: TenantActions.TenantSetWorkingTenantName;
    tenantName: string;
}

export type KnownAction =
    ISetResource |
    IOverrideActiveTenant |
    ISetBasicTenantSettings |
    ISetBrandConfig |
    IListEmailDomains |
    IListEmailDomainsComplete |
    ITenantListUserMetadataSettings |
    ITenantListUserMetadataSettingsComplete |
    IGetBannerMessage |
    IGetBannerMessageComplete |
    ISetWorkingTenantName;
